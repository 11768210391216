import SectionBuilderConfig from 'molecules/Section/Section.config';

const CashFlowForm = {
  name: 'CashFlowForm',
  inputs: [
    { name: 'heading', type: 'text', defaultValue: 'Title goes here' },
    { name: 'subheading', type: 'text', defaultValue: '' },

    ...SectionBuilderConfig.inputs,
  ],
};

export default CashFlowForm;
