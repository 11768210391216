/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable func-style */
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import type { iconIds } from 'atoms/Icons/Icon.types';

import Container from 'quarks/Container';
import Heading from 'quarks/Heading';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';

import Button from 'molecules/Button/Button';
import TextField from 'molecules/TextField/TextField';

import type { HeroProps } from 'components/Hero/HeroStandard/heroTypesAndData';

import { getBackgroundIsDark, getButtonHierarchy } from 'utils/functions';

import type { FC } from 'react';
import type { SubmitHandler } from 'react-hook-form';

const CashFlowForm: FC<HeroProps> = ({
  eyebrow,
  heading,
  subheading,
  background,
  featureListHeading,
  featureList,
  hsForm,
  leftDoodle,
  rightDoodle,
  sectionId,
  ...props
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const pageURL = props?.builderState?.state?.location?.pathname;
  const [serverResponse, setServerResponse] = React.useState('');

  type Inputs = {
    firstname: string;
    lastname: string;
    companyname: string;
    email: string;
    pageURL: string;
    formName: string;
    heading?: string;
  };
  const schema = yup.object<Inputs>().shape({
    email: yup.string().email().required(),
    firstname: yup.string().required(),
    lastname: yup.string().required(),
    companyname: yup.string().required(),
  });
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<Inputs>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(schema),
  });
  // eslint-disable-next-line no-console
  const onSubmit: SubmitHandler<Inputs> = async data => {
    const response = await fetch('/api/cashFlowForm', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then(res => res.json());
    setServerResponse(response);
  };

  // eslint-disable-next-line no-console

  const backgroundIsDark = getBackgroundIsDark(background);

  return (
    <>
      {serverResponse && serverResponse.success ? (
        <Container boxShadow="0px 24px 48px -12px rgba(0,5,203,0.18)" paddingAll={24}>
          <h2>Success</h2>
          <p>Thanks for your interest in Lineup.ai! A representative will reach out shortly.</p>
        </Container>
      ) : (
        <Container boxShadow="0px 24px 48px -12px rgba(0,5,203,0.18)" paddingAll={24}>
          <div>
            {heading && (
              <Heading
                as="h3"
                textStyle="xs"
                md={{ textStyle: 'xs' }}
                xl={{ textStyle: 'xs' }}
                fontWeight="semiBold"
                marginBottom={6}
              >
                {heading}
              </Heading>
            )}
            {subheading && (
              <Text
                as="div"
                display="flex"
                flexDirection="column"
                textColor={backgroundIsDark ? 'common-white' : 'gray-700'}
                textStyle="lg"
                gap="8px"
                md={{ textStyle: 'xl' }}
                marginBottom={12}
              >
                {subheading}
              </Text>
            )}
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" {...register('pageURL', { value: pageURL })} defaultValue={pageURL} />
            <input type="hidden" {...register('formName', { value: heading })} defaultValue="CashFlowForm" />

            <Controller
              name="firstname"
              control={control}
              render={({ field }) => (
                <TextField
                  label="First Name"
                  required
                  {...field}
                  marginBottom={12}
                  errorMessage={errors.firstname?.message}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  error={errors.firstname?.message ? 'error' : ''}
                />
              )}
            />
            <Controller
              name="lastname"
              control={control}
              render={({ field }) => (
                <TextField
                  label="Last Name"
                  required
                  {...field}
                  marginBottom={12}
                  errorMessage={errors.lastname?.message}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  error={errors.lastname?.message ? 'error' : ''}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Company Email"
                  marginBottom={12}
                  required
                  errorMessage={errors.email?.message}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  error={errors.email?.message ? 'error' : ''}
                />
              )}
            />
            <Controller
              name="companyname"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Company Name"
                  marginBottom={12}
                  required
                  errorMessage={errors.companyname?.message}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  error={errors.companyname?.message ? 'error' : ''}
                />
              )}
            />

            <Button type="submit" iconSize="1.25rem" variant="contained" marginTop={16}>
              Submit
            </Button>
          </form>
        </Container>
      )}
    </>
  );
};
export default CashFlowForm;
