import { Builder } from '@builder.io/react';
import ArticleOutlineConfig from 'builder/Article.config';
import BlockQuoteBuilderConfig from 'builder/BlockQuoteBuilder.config';
import HeadingsBuilderConfig from 'builder/Headings.config';
import HorizontalRuleConfig from 'builder/HorizontalRule.config';
import ImageBuilderConfig from 'builder/Image.config';
import CopyBuilderConfig from 'builder/Text.config';
import { lazy } from 'react';

import AccordionBuilderConfig from 'components/Accordion/Accordion.config';
import BlogListingConfig from 'components/Blog/BlogListing.config';
import CardDeckBuilderConfig from 'components/CardDeck/CardDeck.config';
import CareerListingBuilderConfig from 'components/CareerListing/CareerListing.config';
import CaseStudyListingBuilderConfig from 'components/CaseStudyListing/CaseStudyListing.config';
import SwitchbackBuilderConfig from 'components/ComponentSwitchback/ComponentSwitchback.config';
import ContentEditor from 'components/ContentEditor/ContentEditor';
import ContentEditorBuilderConfig from 'components/ContentEditor/ContentEditor.config';
import ConversionPanelBuilderConfig from 'components/ConversionPanel/ConversionPanel.config';
import FAQBuilderConfig from 'components/FAQ/FAQ.config';
import FeatureGridBuilderConfig from 'components/FeatureGrid/FeatureGrid.config';
import CalculatorBuilderConfig from 'components/ForecastCalculator/componentCalculatorTypesConfig';
import CashFlowForm from 'components/Forms/CashFlowForm/CashFlowForm.config';
import DemoForm from 'components/Forms/DemoForm/DemoForm.config';
import HeadingBuilderConfig from 'components/Heading/Heading.config';
import HeroFormConfig from 'components/Hero/HeroForm/HeroForm.config';
import HeroHomepageConfig from 'components/Hero/HeroHomepage/HeroHomepage.config';
import HeroBuilderConfig from 'components/Hero/HeroStandard/Hero.config';
import HeroPricingBuilderConfig from 'components/HeroPricing/HeroPricing.config';
import HorizontalSwitcherBuilderConfig from 'components/HorizontalSwitcher/HorizontalSwitcher.config';
import HsFormConfig from 'components/HsForm/HsForm.config';
import integrationsCarouselDataField from 'components/IntegrationsCarousel/IntegrationsCarousel.config';
import integrationsBuilderDataField from 'components/IntegrationsList/Integrations.config';
import NewsletterCtaBuilderConfig from 'components/NewsletterCta/NewsletterCta.config';
import partnerListBuilderConfig from 'components/PartnerList/PartnerList.config';
import PricingTableBuilderConfig from 'components/PricingTable/PricingTableBuilderConfig';
import ProfitMarginCalculatorBuilderConfig from 'components/ProfitMarginCalculator/componentProfitMarginCalcConfig';
import ProgressBarSwitcherConfig from 'components/ProgressBarSwitcher/progressBarSwitcher.config';
import InlineQuoteBuilderConfig from 'components/QuoteSlider/InlineQuote/InlineQuote.config';
import QuoteSliderBuilderConfig from 'components/QuoteSlider/QuoteSlider.config';
import ResourceListingBuilderConfig from 'components/ResourceListing/ResourceListing.config';
import RoiCalculatorBuilderConfig from 'components/RoiCalculator/componentRoiCalcConfig';
import SolutionsCardGridBuilderConfig from 'components/SolutionsCardGrid/SolutionsCardGrid.config';
import ComponentInlineComponentStatsBuilderConfig from 'components/Stats/InlineComponentStats/InlineComponentStats.config';
import ComponentStatsBuilderConfig from 'components/Stats/Stats.config';
import ComponentStatsBarBuilderConfig from 'components/Stats/StatsBar.config';
import SupportListingBuilderConfig from 'components/Support/SupportListing.config';
import SwitcherBuilderConfig from 'components/Switcher/Switcher.config';
import TimelineBuilderConfig from 'components/Timeline/Timeline.config';
import TrustbarBuilderConfig from 'components/TrustBar/TrustBar.config';
import VerticalSwitcherBuilderConfig from 'components/VerticalSwitcher/VerticalSwitcher.config';
import VideoSectionBuilderConfig from 'components/VideoSection/VideoSection.config';

import type { Component } from '@builder.io/sdk';

export const richTextModels = [
  { component: lazy(() => import('builder/BlockQuote')), config: BlockQuoteBuilderConfig },
  { component: lazy(() => import('builder/Headings')), config: HeadingsBuilderConfig },
  { component: lazy(() => import('builder/HorizontalRule')), config: HorizontalRuleConfig },
  { component: lazy(() => import('builder/Image')), config: ImageBuilderConfig },
  { component: lazy(() => import('builder/Outline')), config: ArticleOutlineConfig },
  { component: lazy(() => import('builder/Text')), config: CopyBuilderConfig },
  { component: lazy(() => import('components/Stats/StatBar')), config: ComponentStatsBarBuilderConfig },
  { component: lazy(() => import('components/QuoteSlider/InlineQuote/InlineQuote')), config: InlineQuoteBuilderConfig },
  {
    component: lazy(() => import('components/Stats/InlineComponentStats/InlineComponentStats')),
    config: ComponentInlineComponentStatsBuilderConfig,
  },
];

const componentDataModel = [
  { component: ContentEditor, config: ContentEditorBuilderConfig },
  { component: lazy(() => import('components/Forms/DemoForm/DemoForm')), config: DemoForm },
  { component: lazy(() => import('components/Forms/CashFlowForm/CashFlowForm')), config: CashFlowForm },
  { component: lazy(() => import('components/HsForm/HsForm')), config: HsFormConfig },
  { component: lazy(() => import('components/Blog/BlogListing')), config: BlogListingConfig },
  {
    component: lazy(() => import('components/ComponentSwitchback/ComponentSwitchback')),
    config: SwitchbackBuilderConfig,
  },
  { component: lazy(() => import('components/Switcher/Switcher')), config: SwitcherBuilderConfig },
  {
    component: lazy(() => import('components/VerticalSwitcher/VerticalSwitcher')),
    config: VerticalSwitcherBuilderConfig,
  },
  { component: lazy(() => import('components/FAQ/FAQ')), config: FAQBuilderConfig },
  { component: lazy(() => import('components/FeatureGrid/FeatureGrid')), config: FeatureGridBuilderConfig },
  { component: lazy(() => import('components/CareerListing/CareerListing')), config: CareerListingBuilderConfig },
  { component: lazy(() => import('components/ResourceListing/ResourceListing')), config: ResourceListingBuilderConfig },
  {
    component: lazy(() => import('components/CaseStudyListing/CaseStudyListing')),
    config: CaseStudyListingBuilderConfig,
  },
  {
    component: lazy(() => import('components/Support/SupportListing')),
    config: SupportListingBuilderConfig,
  },
  { component: lazy(() => import('components/ConversionPanel/ConversionPanel')), config: ConversionPanelBuilderConfig },
  { component: lazy(() => import('components/Heading/Heading')), config: HeadingBuilderConfig },
  { component: lazy(() => import('components/Hero/HeroStandard/Hero')), config: HeroBuilderConfig },
  { component: lazy(() => import('components/Hero/HeroHomepage/HeroHomepage')), config: HeroHomepageConfig },
  { component: lazy(() => import('components/Hero/HeroForm/HeroForm')), config: HeroFormConfig },
  { component: lazy(() => import('components/HeroPricing/HeroPricing')), config: HeroPricingBuilderConfig },
  {
    component: lazy(() => import('components/IntegrationsList/IntegrationsList')),
    config: integrationsBuilderDataField,
  },
  {
    component: lazy(() => import('components/IntegrationsCarousel/IntegrationsCarousel')),
    config: integrationsCarouselDataField,
  },
  { component: lazy(() => import('components/NewsletterCta/NewsletterCta')), config: NewsletterCtaBuilderConfig },
  { component: lazy(() => import('components/PartnerList')), config: partnerListBuilderConfig },
  { component: lazy(() => import('components/PricingTable/PricingTable')), config: PricingTableBuilderConfig },
  { component: lazy(() => import('components/ProgressBarSwitcher/index')), config: ProgressBarSwitcherConfig },
  { component: lazy(() => import('components/QuoteSlider/QuoteSlider')), config: QuoteSliderBuilderConfig },
  { component: lazy(() => import('components/Stats/ComponentStats')), config: ComponentStatsBuilderConfig },
  { component: lazy(() => import('components/Timeline/Timeline')), config: TimelineBuilderConfig },
  { component: lazy(() => import('components/TrustBar/TrustBar')), config: TrustbarBuilderConfig },
  { component: lazy(() => import('components/VideoSection/VideoSection')), config: VideoSectionBuilderConfig },
  { component: lazy(() => import('components/Accordion/Accordion')), config: AccordionBuilderConfig },
  { component: lazy(() => import('components/CardDeck/CardDeck')), config: CardDeckBuilderConfig },
  {
    component: lazy(() => import('components/HorizontalSwitcher/HorizontalSwitcher')),
    config: HorizontalSwitcherBuilderConfig,
  },
  {
    component: lazy(() => import('components/ForecastCalculator/ComponentCalculator')),
    config: CalculatorBuilderConfig,
  },
  {
    component: lazy(() => import('components/RoiCalculator/ComponentRoiCalculator')),
    config: RoiCalculatorBuilderConfig,
  },
  {
    component: lazy(() => import('components/SolutionsCardGrid/SolutionsCardGrid')),
    config: SolutionsCardGridBuilderConfig,
  },
  {
    component: lazy(() => import('components/ProfitMarginCalculator/ComponentProfitMarginCalculator')),
    config: ProfitMarginCalculatorBuilderConfig,
  },
  ...richTextModels,
] as const;

const customComponentsRegistration = () =>
  componentDataModel.map(component => Builder.registerComponent(component.component, component.config as Component));

export default customComponentsRegistration;
